import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import About from "./Components/About";
import Photography from "./Components/Photography";
import Videography from "./Components/Videography";
import Other from "./Components/Other";
import Equipment from "./Components/Equipment";
import GraphicDesign from "./Components/GraphicDesign";
import ITandDigital from "./Components/ITandDigital";
import RouterScroller from "./Components/RouterScroller";
import GoodCause from "./Components/GoodCause";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "white" }}>
      <Router>
        <RouterScroller />
        <Routes>
          <Route exact path="/" element={<About />} />
          <Route exact path="/Photography" element={<Photography />} />
          <Route exact path="/Videography" element={<Videography />} />
          <Route exact path="/Other" element={<Other />} />
          <Route exact path="/ITandDigital" element={<ITandDigital />} />
          <Route exact path="/Equipment" element={<Equipment />} />
          <Route exact path="/GraphicDesign" element={<GraphicDesign />} />
          <Route exact path="/GoodCause" element={<GoodCause />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
