import React, { useState } from "react";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SortBy = (props) => {
  const [sortBy, setSortBy] = React.useState("year");
  const [options, setOptions] = React.useState([
    "year",
    "country",
    "classification",
  ]);
  const [direction, setDirection] = React.useState("up");

  function setUp() {
    setDirection("up");
    props.passSortBy(sortBy, direction);
  }

  function setDown() {
    setDirection("down");
    props.passSortBy(sortBy, direction);
  }

  const handleChangeSortBy = (event) => {
    let oldSortBy = sortBy;
    setSortBy(event.target.value);
    props.passSortBy(event.target.value, direction);
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <Box sx={{ p: 2, minWidth: "120px", display: "flex" }}>
        <Box>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "black" }}
            >
              Sort
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortBy}
              label="Select"
              onChange={handleChangeSortBy}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
              autoWidth
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  selected
                  sx={{
                    "&&.Mui-selected": {
                      backgroundColor: "whitesmoke",
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10px",
          }}
        >
          <Button onClick={setUp} style={{ width: "10px", height: "30px" }}>
            <KeyboardArrowUpIcon
              style={{ color: "black" }}
            ></KeyboardArrowUpIcon>
          </Button>
          <Button onClick={setDown} style={{ width: "10px", height: "30px" }}>
            <KeyboardArrowDownIcon
              style={{ color: "black" }}
            ></KeyboardArrowDownIcon>
          </Button>
        </Box>
      </Box>{" "}
    </div>
  );
};

export default SortBy;
