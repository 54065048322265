import React from "react";
import Nav from "./Nav";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Button, Card, Typography } from "@mui/material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import RecommendIcon from "@mui/icons-material/Recommend";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CodeIcon from "@mui/icons-material/Code";

export default function Other() {
  let buttonStyle = {
    padding: "0px",
    color: "black",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    textTransform: "none",
    flexGrow: "1",
    borderRadius: "0.3rem",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  };

  return (
    <div style={{ maxWidth: "940px", margin: "0 auto", marginTop: "40px" }}>
      <Nav />
      <Box
        style={{
          maxWidth: "900px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
          alignItems: "stretch",
          gap: "10px",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Button sx={buttonStyle}>
          <Link to="/ITandDigital">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <CodeIcon></CodeIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Professional Services
              </Typography>
            </Box>
          </Link>
        </Button>
        <Button sx={buttonStyle}>
          <Link to="/Equipment">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <HomeRepairServiceIcon></HomeRepairServiceIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Equipment i use...
              </Typography>
            </Box>
          </Link>
        </Button>
        <Button sx={buttonStyle}>
          <Link to="/GraphicDesign">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <DesignServicesIcon></DesignServicesIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Graphic Design{" "}
              </Typography>
            </Box>
          </Link>
        </Button>
        <Button sx={buttonStyle}>
          <Link to="/GoodCause">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
              }}
            >
              <RecommendIcon></RecommendIcon>
              <Typography style={{ marginLeft: "10px" }}>
                Support things I support{" "}
              </Typography>
            </Box>
          </Link>
        </Button>
      </Box>
      <Footer></Footer>
    </div>
  );
}
