import React from "react";
import {
  Box,
  Button,
  CardActionArea,
  Card,
  CardMedia,
  Typography,
} from "@mui/material";
import "./VideoCard.css";

export default function VideoCard(props) {
  return (
    <div>
      <Card
        style={{
          borderRadius: "0.5rem",
          boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
          width: "95vw",
          maxWidth: "900px",
        }}
      >
        <Box>
          <Box
            style={{
              width: "95vw",
              maxWidth: "900px",
            }}
          >
            <iframe
              style={{ maxHeight: "75vh" }}
              src={props.videoInfo.url}
              title="YouTube video player"
              frameborder="0"
              allow="fullscreen, accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen="true"
            ></iframe>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
              marginRight: "5px",
              marginBottom: "10px",
            }}
          >
            <Box>
              <Typography style={{ fontWeight: "bold" }}>
                {props.videoInfo.title}
              </Typography>
              <Typography style={{ fontSize: "0.8rem" }}>
                {props.videoInfo.year}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{
                  fontSize: "0.9rem",
                  wordWrap: "break-all",
                }}
              >
                {props.videoInfo.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
