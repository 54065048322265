import Nav from "./Nav";
import { db } from "../firebase";
import React, { useState, useEffect, useRef } from "react";
import PhotoCard from "./PhotoCard";
import "./Photography.css";
import { Button, CardActionArea, CardActions } from "@mui/material";
import PhotoFilter from "./PhotoFilter";
import { Box } from "@mui/system";
import SortBy from "./SortBy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Footer from "./Footer";
import CircularProgress from "@mui/material/CircularProgress";

export default function Photography() {
  const [allPhotos, setAllPhotos] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNumberOfPhotos, setShowNumberOfPhotos] = useState(10);

  useEffect(() => {
    const fbListener = subscribeToAllPhotos();
    window.addEventListener("scroll", handleScroll);

    return () => {
      fbListener();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const trackLength = documentHeight - windowHeight;
    const distanceFromBottom = trackLength - scrollTop;
    if (distanceFromBottom < 1600) {
      setShowNumberOfPhotos((showNumberOfPhotos) => showNumberOfPhotos + 3);
    }
  }

  function subscribeToAllPhotos() {
    const firebaseListener = db
      .collection("allPhotos")
      .doc("photos")
      .onSnapshot((doc) => {
        let fireBaseData = doc.data();
        let shuffled = shuffleArray(fireBaseData.photoArray);
        let sortHorizontal = sortHorizontals(shuffled);
        setAllPhotos(sortHorizontal);
        setFilteredPhotos(sortHorizontal);
        setLoading(false);
      });
    return firebaseListener;
  }

  function resetFilter() {
    setFilter([]);
    setFilteredPhotos([]);
  }

  function sortHorizontals(array) {
    let helper = array;
    let needsVerticalNext = false;
    if (helper.length > 2) {
      for (var i = 0; i < helper.length; i++) {
        if (needsVerticalNext == true && helper[i].vertical == false) {
          let previousVertItem = helper[i - 1];
          let holdHorizontal = helper[i];
          helper[i] = previousVertItem;
          helper[i - 1] = holdHorizontal;
          needsVerticalNext = true;
        } else if (helper[i].vertical == true && needsVerticalNext == false) {
          needsVerticalNext = true;
        } else if (needsVerticalNext == true && helper[i].vertical == true) {
          needsVerticalNext = false;
        }
      }
    }
    return helper;
  }

  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  function shuffleArray(array) {
    let helper = array;
    for (var i = helper.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = helper[i];
      helper[i] = helper[j];
      helper[j] = temp;
    }

    return helper;
  }

  function sortBy(value, direction) {
    //value = class,year,country
    let array = [...allPhotos];
    if (direction == "up") {
      array.sort((a, b) => {
        const nameA = a[value].toUpperCase(); // ignore upper and lowercase
        const nameB = b[value].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
        // names must be equal
      });
    } else {
      array.sort((a, b) => {
        const nameA = a[value].toUpperCase(); // ignore upper and lowercase
        const nameB = b[value].toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        } else {
          return 0;
        }
        // names must be equal
      });
    }
    let sortHor = sortHorizontals(array);
    setAllPhotos(sortHor);
  }

  function addToFilter(newValue, oldValue) {
    let newFilter = filter;

    if (filter.includes(oldValue)) {
      let oldIndex = filter.indexOf(oldValue);
      newFilter.splice(oldIndex, 1);
      newFilter.push(newValue);
      setFilter(newFilter);
    } else {
      newFilter.push(newValue);
      setFilter(newFilter);
    }

    filterPhotos(newFilter);
  }

  function filterPhotos(newFilter) {
    setLoading(true);
    let photos = [...allPhotos];
    let afterFiltered = [];
    let filters = newFilter;
    photos.forEach((photo) => {
      let foundFilters4Photo = 0;
      for (const [key, value] of Object.entries(photo)) {
        filters.forEach((filter) => {
          if (filter == value) {
            foundFilters4Photo = foundFilters4Photo + 1;
          }
        });
      }

      if (foundFilters4Photo == filters.length) {
        if (!afterFiltered.includes(photo)) {
          afterFiltered.push(photo);
        }
      }
    });
    let newArray = sortHorizontals(afterFiltered);
    setLoading(false);
    setFilteredPhotos(newArray);
  }

  return (
    <div style={{ maxWidth: "940px", margin: "0 auto" }}>
      <Nav />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "35vh",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <Box style={{ display: "flex", justifyContent: "left" }}>
            <Button
              sx={{
                width: "100%",
                margin: "10px",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                },
                textTransform: "none",
              }}
              onClick={() => {
                if (showFilter) {
                  setFilter([]);
                  setFilteredPhotos([]);
                }
                setShowFilter(!showFilter);
              }}
            >
              {showFilter ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: "10px",
                  }}
                >
                  <ArrowBackIosIcon></ArrowBackIosIcon>
                  <a>Back</a>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: "10px",
                  }}
                >
                  <FilterAltIcon></FilterAltIcon>
                  <a>Category</a>
                </Box>
              )}
            </Button>
          </Box>
          {showFilter ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "16px",
                alignItems: "stretch",
              }}
            >
              <PhotoFilter
                allPhotos={allPhotos}
                passSelectedYear={addToFilter}
                passSelectedCountry={addToFilter}
                passSelectedClassification={addToFilter}
                resetFilter={resetFilter}
              >
                Filter Component
              </PhotoFilter>{" "}
            </Box>
          ) : null}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "16px",
              alignItems: "stretch",
            }}
          >
            {showFilter && !loading ? (
              filteredPhotos.length == 0 ? (
                <p>
                  No results for your query... please change your selection.
                </p>
              ) : (
                filteredPhotos
                  .slice(0, showNumberOfPhotos)
                  .map((image, index) => {
                    return (
                      <PhotoCard key={image.id} imageInfo={image}></PhotoCard>
                    );
                  })
              )
            ) : (
              allPhotos.slice(0, showNumberOfPhotos).map((image, index) => {
                return (
                  <PhotoCard
                    key={image.id}
                    imageInfo={image}
                    style={{ flexGrow: "1" }}
                  ></PhotoCard>
                );
              })
            )}
          </Box>
        </div>
      )}
      <Footer></Footer>
    </div>
  );
}
