import React, { useState, useEffect, useRef } from "react";
import Nav from "./Nav";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography } from "@mui/material";
import { flexbox } from "@mui/system";
import { Box } from "@mui/system";
import insta_black from "../assets/insta_black.png";
import insta_color from "../assets/insta_color.png";
import mailIcon from "../assets/mail_icon.png";
import { Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import frog_color from "../assets/frog_color_noBorder.png";
import frog_black from "../assets/frog_black_noBorder.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import yt_black from "../assets/yt_black.png";
import yt_color from "../assets/yt_color.png";
import { Link } from "react-router-dom";
import Impressum from "./Impressum";
import ShareIcon from "@mui/icons-material/Share";
import { isMobile } from "react-device-detect";

const theme = createTheme({
  palette: {
    colorfull: {
      main: "#EA4B06",
    },
  },
});

export default function Footer() {
  const [showBlackfrog, setBlackFrog] = React.useState(true);
  const [showBlackInsta, setBlackInsta] = React.useState(true);
  const [visible, setVisible] = useState(false);

  let links = {
    mobile: {
      instagram: "instagram://user?username=ryan.steimer",
      youTube:
        "intent://@ryan-steimer/#Intent;scheme=vnd.youtube;package=com.google.android.youtube;S.browser_fallback_url=market://details?id=com.google.android.youtube;end;",
    },

    web: {
      instagram: "https://www.instagram.com/ryan.steimer/",
      youTube: "https://www.youtube.com/@ryan-steimer",
    },
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 800) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  function toggleFrog() {
    setBlackFrog(!showBlackfrog);
  }

  function toggleInsta() {
    setBlackInsta(!showBlackInsta);
  }

  return (
    <div style={{ maxWidth: "940px", margin: "0 auto" }}>
      <ThemeProvider theme={theme}>
        <Card
          style={{
            minHeight: "200px",
            textAlign: "center",
            marginTop: "50px",
            padding: "10px",
            overflowWrap: "break-word",
            marginLeft: "10px",
            marginRight: "10px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 10px 10px -10px",
            marginBottom: "65px",
          }}
        >
          {visible ? (
            <Box style={{ marginBottom: "20px" }}>
              <div
                style={{
                  margin: "0 auto",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 20px -20px",
                }}
              >
                <Button
                  onClick={scrollToTop}
                  sx={{
                    color: "black",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "whitesmoke",
                    },
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Box style={{ paddingBottom: "5px" }}>
                    <KeyboardArrowUpIcon
                      style={{
                        color: "black",
                        padding: "0px",
                      }}
                    ></KeyboardArrowUpIcon>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        padding: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Beam me up!
                    </Typography>
                  </Box>
                </Button>
              </div>
            </Box>
          ) : null}
          <Box
            style={{
              display: "flex",
              justifiyContent: "left",
              gap: "20px",

              flexWrap: "wrap",
            }}
            onMouseEnter={() => {
              toggleInsta();
              toggleFrog();
            }}
            onClick={() => {
              toggleInsta();
              toggleFrog();
            }}
            onMouseLeave={() => {
              toggleInsta();
              toggleFrog();
            }}
          >
            <Box
              style={{
                display: "flex",
                justifiyContent: "left",
                gap: "10px",
              }}
            >
              <Box>
                <Typography textAlign={"left"}>
                  <Box>
                    <Typography variant="h6">Ryan Steimer</Typography>
                    <Typography style={{ fontSize: "01rem" }}>
                      {" "}
                      Photography & Videography
                    </Typography>
                    {/*                     <Typography style={{ fontSize: "01rem" }}>
                      {" "}
                      IT && Full Stack Development
                    </Typography> */}
                    <Box
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <a
                          href={
                            isMobile
                              ? links.mobile.instagram
                              : links.web.instagram
                          }
                          target="_blank"
                        >
                          <img
                            src={showBlackInsta ? insta_black : insta_color}
                            style={{
                              height: "30px",
                              paddingTop: "0px",
                            }}
                            alt="Insta-Link"
                          ></img>
                        </a>
                      </Box>
                      <Box>
                        <a
                          href={
                            isMobile ? links.mobile.youTube : links.web.youTube
                          }
                          target="_blank"
                        >
                          <img
                            src={showBlackInsta ? yt_black : yt_color}
                            style={{
                              height: "30px",
                              paddingTop: "0px",
                            }}
                            alt="YouTube-Link"
                          ></img>
                        </a>
                      </Box>
                      <Box>
                        {" "}
                        <a href="mailto:ryan@steimer.tech.de?subject=Contact, Ryan Steimer, Website&body=Hey Ryan, ">
                          <img
                            src={mailIcon}
                            style={{
                              width: "39px",
                              paddingTop: "1px",
                            }}
                            alt="Mail-Link"
                          ></img>
                        </a>
                      </Box>
                    </Box>
                    <div>
                      <a href="mailto:ryan@steimer.tech.de?subject=Contact, Ryan Steimer, Website&body=Hey Ryan, ">
                        <Box
                          style={{
                            paddingTop: "5px",
                            display: "flex",
                            alignItems: "left",
                            flexWrap: "wrap",
                            flexDirection: "column",
                          }}
                        >
                          <Typography style={{ fontSize: "0.9rem" }}>
                            ryan@steimer-tech.de
                          </Typography>{" "}
                        </Box>
                      </a>
                      <Typography style={{ fontSize: "0.7rem" }}>
                        Munich, Germany{" "}
                      </Typography>{" "}
                    </div>
                  </Box>
                </Typography>
              </Box>
            </Box>

            <Box
              style={{
                marginLeft: "auto",
              }}
            >
              <img
                id="image-hover"
                src={showBlackfrog ? frog_black : frog_color}
                style={{
                  maxHeight: "200px",
                  paddingTop: "12px",
                  transform: "rotate(-15deg)",
                }}
                alt="Froggo"
              ></img>
            </Box>
          </Box>{" "}
          <Typography style={{ fontSize: "0.6rem", padding: "10px" }}>
            {"<< "}hand-crafted with love {"<3"}
            {" >>"}
          </Typography>
          <Impressum />
        </Card>
      </ThemeProvider>
    </div>
  );
}
