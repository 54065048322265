import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function PhotoCard(props) {
  const [open, setOpen] = React.useState(false);
  const [filterStrength, setFilterStrength] = React.useState(100);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (filterStrength > 0) {
        setFilterStrength((filterStrength) => filterStrength - 8);
      } else {
        clearInterval(interval);
      }
    }, 120);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0.25, 0.80)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <Card
          style={{
            backgroundColor: "#F2F2F2",
            borderRadius: "0.0rem",
          }}
        >
          <CardMedia
            style={{
              maxWidth: "95vw",
              maxHeight: "80vh",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            component="img"
            image={props.imageInfo.url}
            alt={props.imageInfo.name}
          />
          <Card
            style={{
              marginTop: "0px",
              borderRadius: "0rem",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
          >
            <div style={{ padding: "10px" }}>
              <Typography> {props.imageInfo.name}</Typography>
              <Typography>{props.imageInfo.country}</Typography>
              <Typography>{props.imageInfo.year}</Typography>
              <a
                href={`http://maps.google.com/maps?q=${props.imageInfo.latitude},${props.imageInfo.longitude}`}
                target="_blank"
              >
                <Box
                  style={{
                    paddingTop: "5px",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <LocationOnIcon></LocationOnIcon>
                  <Typography>Location</Typography>
                </Box>
              </a>
            </div>
          </Card>
        </Card>
      </Backdrop>
      <Box>
        <CardActionArea
          onClick={() => {
            handleToggle();
          }}
        >
          <Card
            style={{
              borderRadius: "0.5rem",
              boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
              overflow: "hidden",
            }}
          >
            <CardMedia
              key={props.imageInfo.id}
              sx={{
                webkitFilter: `grayscale(${filterStrength}%)` /* Safari 6.0 - 9.0 */,
                filter: `grayscale(${filterStrength}%)`,
                ":hover": {
                  transform: "scale(1.02)", // theme.shadows[20]
                },
              }}
              style={
                props.imageInfo.vertical == true
                  ? {
                      borderRadius: "0.5rem",
                      width: "342px",
                      maxWidth: "92vw",
                      transition: "transform .5s ease",
                    }
                  : {
                      borderRadius: "0.5rem",
                      width: "700px",
                      maxWidth: "92vw",
                      transition: "transform .5s ease",
                    }
              }
              component="img"
              image={props.imageInfo.url}
              alt={props.imageInfo.name}
              loading="lazy"
            />
          </Card>
        </CardActionArea>
      </Box>
    </div>
  );
}
