import { db } from "../firebase";
import React, { useState, useEffect, useRef } from "react";
import Nav from "./Nav";
import { Box, Typography } from "@mui/material";
import VideoCard from "./VideoCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Footer from "./Footer";
import { Button, CardActionArea, CardActions } from "@mui/material";
import VideoFilter from "./VideoFilter";
import InfoIcon from "@mui/icons-material/Info";

export default function Videography() {
  const [allVideos, setAllVideos] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fbListener = subscribeToAllVideos();

    return () => {
      fbListener();
    };
  }, []);

  function subscribeToAllVideos() {
    const firebaseListener = db
      .collection("allVideos")
      .doc("videos")
      .onSnapshot((doc) => {
        let fireBaseData = doc.data();
        let shuffled = shuffleArray(fireBaseData.videoArray);
        let sortedByValue = sortByKey(shuffled, "rating");
        setAllVideos(sortedByValue);
        setFilteredVideos(sortedByValue);
        setLoading(false);
      });
    return firebaseListener;
  }

  function resetFilter() {
    setFilter([]);
    setFilteredVideos([]);
  }

  function shuffleArray(array) {
    let helper = array;
    for (var i = helper.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = helper[i];
      helper[i] = helper[j];
      helper[j] = temp;
    }

    return helper;
  }

  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  function addToFilter(newValue, oldValue) {
    let newFilter = filter;

    if (filter.includes(oldValue)) {
      let oldIndex = filter.indexOf(oldValue);
      newFilter.splice(oldIndex, 1);
      newFilter.push(newValue);
      setFilter(newFilter);
    } else {
      newFilter.push(newValue);
      setFilter(newFilter);
    }

    filterVideos(newFilter);
  }

  function filterVideos(newFilter) {
    setLoading(true);
    let videos = [...allVideos];
    let afterFiltered = [];
    let filters = newFilter;
    videos.forEach((video) => {
      let foundFilters4Video = 0;
      for (const [key, value] of Object.entries(video)) {
        filters.forEach((filter) => {
          if (filter == value) {
            foundFilters4Video = foundFilters4Video + 1;
          }
        });
      }

      if (foundFilters4Video == filters.length) {
        if (!afterFiltered.includes(video)) {
          afterFiltered.push(video);
        }
      }
    });
    setLoading(false);
    setFilteredVideos(afterFiltered);
  }

  return (
    <div style={{ maxWidth: "940px", margin: "0 auto" }}>
      <Nav />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "15px",
          alignItems: "center",
        }}
      >
        <Box>
          <InfoIcon style={{ marginLeft: "5px" }}></InfoIcon>
        </Box>
        <Box>
          <Typography
            style={{
              marginLeft: "5px",
              fontSize: "0.7rem",
              wordWrap: "break-all",
              marginBottom: "3px",
            }}
          >
            By playing the videos below, you agree to some data being forwarded
            to YouTube/Google.
          </Typography>
        </Box>
      </Box>

      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{
            width: "95%",
            margin: "10px",
            color: "black",
            "&:hover": {
              backgroundColor: "whitesmoke",
              color: "black",
            },
            textTransform: "none",
          }}
          onClick={() => {
            if (showFilter) {
              setFilter([]);
              setFilteredVideos([]);
            }
            setShowFilter(!showFilter);
          }}
        >
          {showFilter ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "10px",
              }}
            >
              <ArrowBackIosIcon></ArrowBackIosIcon>
              <a>Back</a>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "10px",
              }}
            >
              <FilterAltIcon></FilterAltIcon>
              <a>Filter</a>
            </Box>
          )}
        </Button>
      </Box>
      {showFilter ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "16px",
            alignItems: "stretch",
          }}
        >
          <VideoFilter
            allVideos={allVideos}
            passSelectedYear={addToFilter}
            passSelectedCountry={addToFilter}
            passSelectedType={addToFilter}
            passSelectedClassification={addToFilter}
            resetFilter={resetFilter}
          >
            Filter Component
          </VideoFilter>{" "}
        </Box>
      ) : null}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          alignItems: "stretch",
        }}
      >
        {showFilter && !loading ? (
          filteredVideos.length == 0 ? (
            <p>No results for your query... please change the filter.</p>
          ) : (
            filteredVideos.map((video, index) => {
              return (
                <Box>
                  <VideoCard key={index} videoInfo={video}></VideoCard>
                </Box>
              );
            })
          )
        ) : (
          allVideos.map((video, index) => {
            return (
              <Box>
                <VideoCard key={index} videoInfo={video}></VideoCard>
              </Box>
            );
          })
        )}
      </Box>
      <Footer></Footer>
    </div>
  );
}
