import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";

export default function PhotoFilter(props) {
  const [year, setYear] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [classification, setClassification] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [classifications, setClassifications] = React.useState([]);

  useEffect(() => {
    initCountries();
    initYears();
    initClassification();

    return () => {};
  }, []);

  function clearFilter() {
    setYear("");
    setCountry("");
    setClassification("");
    props.resetFilter();
  }

  const handleChangeYear = (event) => {
    let oldYear = year;
    setYear(event.target.value);
    props.passSelectedYear(event.target.value, oldYear);
  };

  const handleChangeCountry = (event) => {
    let oldCountry = country;
    setCountry(event.target.value);
    props.passSelectedCountry(event.target.value, oldCountry);
  };

  const handleChangeClassification = (event) => {
    let oldClass = classification;
    setClassification(event.target.value);
    props.passSelectedClassification(event.target.value, oldClass);
  };

  function initCountries() {
    let array = [];
    props.allPhotos.forEach((element) => {
      if (!array.includes(element.country)) {
        array.push(element.country);
      }
    });
    array.sort();
    setCountries(array);
  }

  function initYears() {
    let array = [];
    props.allPhotos.forEach((element) => {
      if (!array.includes(element.year)) {
        array.push(element.year);
      }
    });
    array.sort();
    setYears(array);
  }

  function initClassification() {
    let array = [];
    props.allPhotos.forEach((element) => {
      if (
        !array.includes(element.classification) &&
        element.classification != undefined
      ) {
        array.push(element.classification);
      }
    });
    array.sort();
    setClassifications(array);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        alignItems: "center",
        paddingBottom: "20px",
      }}
    >
      <Box>
        <Button
          onClick={clearFilter}
          sx={{
            marginLeft: "8px",
            marginBottom: "10px",
            p: 2,
            minWidth: "200px",
            color: "white",
            backgroundColor: "black",
            opacity: "90%",
            height: "56px",
            "&:hover": {
              backgroundColor: "whitesmoke",
              color: "black",
            },
            textTransform: "none",
          }}
        >
          Clear
        </Button>
        <Box sx={{ p: 1, minWidth: "200px" }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "black" }}
            >
              Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year}
              label="Year"
              onChange={handleChangeYear}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
            >
              {years.map((year, index) => (
                <MenuItem
                  key={index}
                  value={year}
                  selected
                  sx={{
                    "&&.Mui-selected": {
                      backgroundColor: "whitesmoke",
                    },
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ p: 1, minWidth: "200px" }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "black" }}
            >
              Country
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              label="Country"
              onChange={handleChangeCountry}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
            >
              {countries.map((country, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={country}
                    selected
                    sx={{
                      "&&.Mui-selected": {
                        backgroundColor: "whitesmoke",
                      },
                    }}
                  >
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ p: 1, minWidth: "140px" }}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "black" }}
            >
              Classification
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={classification}
              label="Classification"
              onChange={handleChangeClassification}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
            >
              {classifications.map((classification, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={classification}
                    selected
                    sx={{
                      "&&.Mui-selected": {
                        backgroundColor: "whitesmoke",
                      },
                    }}
                  >
                    {classification}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
