import React from "react";
import { Box, Button, CardActionArea, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import Footer from "./Footer";

export default function Impressum() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      {" "}
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0.25, 0.80)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <Card style={{ width: "40rem", padding: "20px", margin: "10px" }}>
          <Typography textAlign={"left"}>
            <Typography
              textAlign={"center"}
              style={{ paddingBottom: "25px" }}
              variant="h6"
            >
              Impressum / Legal Notice
            </Typography>
            <Typography style={{ fontSize: "0.8rem" }}>
              Danziger Strasse 15, 85748 Garching bei München, Germany
            </Typography>
            <Typography style={{ fontSize: "0.8rem" }}>
              Umsatzsteuer-ID / Tax Number: DE328654773
            </Typography>
            <Typography style={{ fontSize: "0.8rem" }}>
              Email: ryan@steimer-tech.de
            </Typography>
            <Typography style={{ fontSize: "0.8rem" }}>
              Tel.: +49 1525 5698604
            </Typography>
          </Typography>
        </Card>
      </Backdrop>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            color: "black",
            boxShadow: "rgba(0, 0, 0, 0.0) 0px 2px 8px",
            textTransform: "none",
            flexGrow: "1",
            borderRadius: "0.3rem",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <CardActionArea
            onClick={() => {
              handleToggle();
            }}
          >
            <Typography style={{ fontSize: "0.8rem", padding: "10px" }}>
              Impressum / Legal Notice
            </Typography>
          </CardActionArea>
        </Card>
      </Box>
    </div>
  );
}
