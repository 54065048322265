import React from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

export default function GraphicDesign() {
  return (
    <div style={{ maxWidth: "940px", margin: "0 auto" }}>
      <Nav />
      <div>
        <Box style={{ marginLeft: "25px" }}>
          <p>Content will follow soon!</p>
        </Box>
      </div>
      <Footer></Footer>
    </div>
  );
}
