import React, { useState } from "react";
import { Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={scrollToTop}
          style={{
            backgroundColor: "white",
            display: visible ? "inline" : "none",
            zIndex: 999,
            position: "fixed",
            cursor: "pointer",
            bottom: "10px",
            borderRadius: "0.4rem",
            opacity: "65%",
            boxShadow: "rgba(0, 0, 0, 1) 0px 35px 30px -10px",
          }}
        >
          <KeyboardArrowUpIcon
            style={{
              color: "black",
              paddingTop: "2px",
            }}
          ></KeyboardArrowUpIcon>
        </Button>
      </Box>
    </div>
  );
};

export default ScrollButton;
